<template>
  <div class="section-input">
    <input
      :value="modelValue"
      @input="handleInput"
      :placeholder="placeholder"
      :class="customClass"
      :type="password ? 'password' : 'text'"
    />
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'placeholder', 'customClass', 'password'],
  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value);
    },
  },
};
</script>

<style lang="sass" scoped>
input
  @apply w-full focus:outline-none
  transition: border-color var(--transition)

  &:focus
    border-color: var(--primary-back-color, var(--klubba-primary-back-color))

.section-input
  transition: color var(--transition)
</style>
