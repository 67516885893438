<template>
  <div class="page-club-questions page-content">
    <KlubbaRichText class="texts">
      <p><b>Additional information</b></p>
      <p>{{ `${club.name} would like to know the below information about you.` }}</p>
    </KlubbaRichText>

    <div class="container">
      <div class="card-wrap">
        <template v-if="questions.length > 0">
          <div
            class="question-item"
            v-for="(question, index) in questions"
            :key="index"
          >
            <p class="question-title" :class="{ 'has-asterisk': question.required }">
              {{ question.label }}
            </p>
            <klubba-input
              v-model="questions[index].value"
              :customClass="customClass"
            />
          </div>
        </template>
        <template v-else>
          <KlubbaRichText>
            <p>There are no questions to answer, please proceed to the next step.</p>
          </KlubbaRichText>
        </template>
      </div>
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Back"
        :nextDisabled="buttonDisabled"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaInput from '@/components/default/KlubbaInput.vue';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';

export default {
  components: {
    KlubbaRichText,
    KlubbaFooter,
    KlubbaInput,
  },
  data() {
    return {
      questions: [],
      answers: [],
      customClass: 'rounded-radius-10 h-13 pl-4 border border-black border-opacity-10',
      radioClass: 'mb-5',
    };
  },
  computed: {
    ...mapState(['club']),
    buttonDisabled() {
      const requiredQuestions = this.questions.filter((question) => question.required);
      return !(requiredQuestions.every((question) => question.value !== ''));
    },
    savedQuestions() {
      return this.$store.getters.clubSpecificByKey('questions');
    },
  },
  mounted() {
    this.questions = this.club.questions.map((question) => ({
      label: question.text,
      value: this.savedQuestions?.find((savedQuestion) => savedQuestion.label === question.text)?.value ?? '',
      required: question.required,
    }));
  },
  watch: {
    savedQuestions() {
      this.questions = this.club.questions.map((question) => ({
        label: question.text,
        value: this.savedQuestions?.find((savedQuestion) => savedQuestion.label === question.text)?.value ?? '',
        required: question.required,
      }));
    },
  },
  methods: {
    ...mapGetters(['clubSpecificByKey']),
    ...mapMutations(['setNewUserClubSpecific']),
    prevPage() {
      this.$router.push(`/${this.club.id}/start-date`);
    },
    nextPage() {
      this.setNewUserClubSpecific({
        questions: this.questions.map((question) => ({
          label: question.label,
          value: question.value,
        })),
      });

      if (this.club?.medical_conditions?.length > 0 || this.club?.allergies?.length > 0) {
        this.$router.push(`/${this.club.id}/additional`);
      } else {
        this.$router.push(`/${this.club.id}/confirmation`);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8

.page-club-questions
  display: flex
  flex-flow: column nowrap
  justify-content: space-between

.card-wrap
  @apply bg-white rounded-radius-10 p-5

.question-item:not(:first-child)
  @apply mt-8

.question-title
  @apply mb-3
  &.has-asterisk:after
    @apply text-danger
    content: ' *'
    display: inline

.button-wrap
  @apply p-8 mt-auto
</style>
